<template>
  <section>

    <div class="columns is-multiline is-desktop">
      <div v-for="book in info" :key="book" class="column is-one-quarter">
        <div class="card">
          <div class="card-image">
            <figure>
              <router-link :to="`/${book.slug}/${book.id}`">
              <img
                :src="book.cover"
                alt="Placeholder image"
              />
              </router-link>
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <router-link :to="`/${book.slug}/${book.id}`"><p class="title is-4">{{book.title}}</p></router-link>
                <p class="subtitle is-6">by {{book.author}}</p>
              </div>
            </div>
          </div>
        </div>
      </div><!-- End loop-->
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "BookList",
  data() {
    return {
      info: [],
      loading: true,
      errored: false,
    };
  },
  mounted() {
    axios
      .get("books")
      .then((response) => {
        this.info = response.data; //check that is the right structure
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>

<style scoped>
</style>

