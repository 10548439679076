<template>
    <div>
        <div class="columns">
            <div class="column">
                <p>©Actionstep</p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>