<template>
<div>
  <BookList/>
</div>
</template>

<script>
// @ is an alias to /src

import BookList from '../components/Books/BookList.vue'
export default {
  name: 'Home',
  components: {
    BookList,
  }
}
</script>
