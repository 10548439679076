<template>
<nav class="navbar is-transparent">
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      <img :src="logo" alt="Bulma: a modern CSS framework based on Flexbox" width="112" height="28">
    </a>
    <div class="navbar-burger" data-target="navbarExampleTransparentExample">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <div id="navbarExampleTransparentExample" class="navbar-menu">
    <div class="navbar-start">
       <router-link to="/" class="navbar-item">Home</router-link>
       <router-link to="/about" class="navbar-item">About</router-link>
       <router-link to="/add-book" class="navbar-item">Add Book</router-link>
    </div>

  </div>
</nav>
</template>

<script>
import logo from '@/assets/logo.png'
    export default {
       name: "NavBar",
       data() {
         return{
           logo
         }
       } 
    }
</script>

<style lang="scss" scoped>

</style>